import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { connect } from "react-redux";
import Footer from "../components/footer";
import { supabase } from "../lib/supabase";
import { JoinPageStyles, colors } from "../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

async function handleAddSecondsSpentOnGame(gameId, seconds) {
	const { error } = await supabase.rpc("add_seconds_spent_on_game", {
		_game_id: gameId,
		_seconds_spent: seconds
	});
	if (error) {
		throw error;
	}
}

function JoinGame({}) {
	const [input, setInput] = useState("");
	const inputRef = useRef("");
	const [inputError, setInputError] = useState(null);
	const [disabled, setDisabled] = useState(true);
	const socketRef = useRef();
	const [game, setGame] = useState(null);
	const gameRef = useRef(null);
	const [joinedGame, setJoinedGame] = useState(false);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [screenHeight, setScreenHeight] = useState(window.innerHeight);
	const [pendingMessages, setPendingMessages] = useState([]);
	const [webviewReady, setWebviewReady] = useState(false);
	const [leaveConfirmation, setLeaveConfirmation] = useState(false);
	const [randomName, setRandomName] = useState("");
	const startTimeRef = useRef(null);
	const iframeRef = useRef(null);
	const [showPopup, setShowPopup] = useState(false);

	const onEndTime = async () => {
		if (startTimeRef.current === null) return;
		const now = new Date().getTime();
		const seconds = (now - startTimeRef.current) / 1000;
		try {
			await handleAddSecondsSpentOnGame(gameRef.current.id, seconds);
		} catch (e) {
			console.log({ e });
		}
		startTimeRef.current = null;
	};

	useEffect(() => {
		if (randomName === "") {
			getRandomName().then((newRandomName) => {
				setRandomName(newRandomName.name);
			});
		}

		async function getRandomName() {
			const res = await axios.get("/api/anonymous-player-name");
			return res.data;
		}
	}, [randomName]);

	useEffect(() => {
		const isAndroid = () => {
			return /Android/i.test(navigator.userAgent);
		};

		if (joinedGame && game !== null && isAndroid()) {
			lockLandscapeOrientation();
		}
	}, [game, joinedGame]);

	useEffect(() => {
		if (!game || !joinedGame) return;
		// case portrait, when should be landscape
		if (screenWidth < screenHeight && game.orientation === "landscape") {
			setShowPopup(true);
		}
		// case landscape, when should be portrait
		else if (screenWidth > screenHeight && game.orientation === "vertical") {
			setShowPopup(true);
		}
		// case correct orientation
		else {
			setShowPopup(false);
		}
	}, [screenWidth, screenHeight, game, joinedGame]);

	const handlePopupRecognition = () => {
		setShowPopup(false);
	};

	const handleJoinGame = async () => {
		// await lockLandscapeOrientation();

		const now = new Date().getTime();
		startTimeRef.current = now;
		if (input === "0000") {
			setJoinedGame(true);
			return;
		}
		socketRef.current.emit("join room", {
			roomId: input.toUpperCase(),
			name: randomName
		});
	};

	const sendMessageToiframe = (message) => {
		if (iframeRef.current) {
			iframeRef.current.contentWindow.postMessage(message, "*");
		}
	};

	useEffect(() => {
		if (pendingMessages.length > 0 && webviewReady) {
			for (let i = 0; i < pendingMessages.length; i++) {
				sendMessageToiframe(pendingMessages[i]);
			}
			setPendingMessages([]);
		}
	}, [webviewReady, pendingMessages]);

	const receiveMessageFromWebView = async (event) => {
		if (event.data?.source?.startsWith("react-devtools") || event.data?.type?.startsWith("webpack")) {
			return;
		}

		// might need to check the origin of the message

		const message = JSON.parse(event.data);
		if (message.name === "ready") {
			setWebviewReady(true);
		} else if (message.name === "log") {
			console.log("PLAYER LOG*", message.content);
		} else if (message.name === "vibrate") {
			if ("vibrate" in navigator) {
				navigator.vibrate(500);
			}
		} else if (message.name === "haptic") {
			if (!("vibrate" in navigator)) {
				return;
			}
			const { type } = message;
			// eslint-disable-next-line default-case
			switch (type) {
				case "light":
					navigator.vibrate(50);
					break;
				case "medium":
					navigator.vibrate(100);
					break;
				case "heavy":
					navigator.vibrate(200);
					break;
			}
		} else if (message.name === "exit") {
			onEndTime().then(() => {
				handleExit();
			});
		} else if (message.name === "exit-confirmation") {
			setLeaveConfirmation(true);
		} else {
			sendMessageFromPlayer(message);
		}
	};

	useEffect(() => {
		window.addEventListener("message", receiveMessageFromWebView);
		// Cleanup
		return () => {
			window.removeEventListener("message", receiveMessageFromWebView);
		};
	}, []);

	useEffect(() => {
		setInput(input.toUpperCase());
		if (input.length === 4) {
			if (input !== "0000") {
				socketRef.current.emit("find room", input);
			} else {
				setDisabled(false);
				supabase
					.from("games")
					.select("*")
					.eq("id", 0)
					.then(({ data, error }) => {
						setGame(data[0]);
						gameRef.current = data[0];
					});
			}
		} else {
			setInputError(null);
			setDisabled(true);
		}
	}, [input]);
	//testing branch protections
	useEffect(() => {
		window.addEventListener("resize", () => {
			setScreenWidth(window.innerWidth);
			setScreenHeight(window.innerHeight);
		});

		window.addEventListener("orientationchange", () => {
			setScreenWidth(window.innerWidth);
			setScreenHeight(window.innerHeight);
		});
		const hostSite = process.env.REACT_APP_HOST_SITE;
		socketRef.current = io(hostSite);
		socketRef.current.on("connect", () => {
			if (inputRef.current !== "") {
				socketRef.current.emit("belong to room", {
					roomId: inputRef.current,
					name: randomName
				});
			}
		});
		socketRef.current.on("does not belong to room", () => {
			handleExit();
		});
		socketRef.current.on("room not found", () => {
			setInputError("Room does not exist");
			setDisabled(true);
		});
		socketRef.current.on("room full", () => {
			setInputError("Room is full");
			setDisabled(true);
		});
		socketRef.current.on("game started", () => {
			setInputError("Game already started");
			setDisabled(true);
		});
		socketRef.current.on("room found", ({ game, versionCompatible }) => {
			setInputError(null);
			setDisabled(false);
			setGame(game);
			gameRef.current = game;
		});
		socketRef.current.on("successful join", () => {
			setJoinedGame(true);
		});
		socketRef.current.on("exit", async () => {
			onEndTime().then(() => {
				handleExit();
			});
		});
		socketRef.current.on("message to player", (message) => {
			if (typeof message === "string") {
				try {
					message = JSON.parse(message);
				} catch (e) {}
			}
			setPendingMessages((prev) => [...prev, message]);
		});
	}, []);

	const sendMessageFromPlayer = (message, roomId = inputRef.current) => {
		socketRef.current.emit("message from player", { roomId, message });
	};

	const handleExit = () => {
		setInput("");
		inputRef.current = "";
		startTimeRef.current = null;
		setJoinedGame(false);
		setGame(null);
		gameRef.current = null;
		setWebviewReady(false);
		setPendingMessages([]);
		setDisabled(true);
		setInputError(null);
		setLeaveConfirmation(false);
		exitFullScreen();
		unlockOrientation(); // might need to await
	};

	if (game !== null && joinedGame) {
		return (
			<div style={{ backgroundColor: "#000" }}>
				<iframe
					ref={iframeRef}
					title="controller"
					id="controller-iframe"
					src={game.controllerUrl}
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						zIndex: 5,
						border: "none",
						width: "100%",
						height: "100%"
					}}
					allowFullScreen
				/>

				{leaveConfirmation ? (
					<div
						style={{
							...JoinPageStyles.leaveConfirmationBox,
							top: screenHeight / 2 - 150,
							left: screenWidth / 2 - 175
						}}
					>
						<div style={JoinPageStyles.normalTextBold}>Are you sure you want to leave?</div>
						<div style={JoinPageStyles.normalText}>Progress will not be saved</div>
						<button
							style={{
								...JoinPageStyles.button,
								backgroundColor: colors.grayMain
							}}
							onClick={() => {
								setLeaveConfirmation(false);
							}}
						>
							<span
								style={{
									...JoinPageStyles.buttonText,
									color: colors.blackMain
								}}
							>
								Stay
							</span>
						</button>
						<button
							style={{
								...JoinPageStyles.button,
								backgroundColor: colors.redMain
							}}
							onClick={() => {
								socketRef.current.emit("individual exit", input);
								onEndTime().then(() => {
									handleExit();
								});
							}}
						>
							<span
								style={{
									...JoinPageStyles.buttonText,
									color: colors.whiteMain
								}}
							>
								Leave
							</span>
						</button>
					</div>
				) : null}
				{showPopup ? (
					<div
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "rgba(255, 255, 255, 0.25)",
							zIndex: 10
						}}
					>
						<div style={JoinPageStyles.leaveConfirmationBox}>
							<div style={JoinPageStyles.normalTextBold}>This game is best played in {game.orientation} mode</div>
							<div style={JoinPageStyles.normalText}>Please rotate your device</div>
							<button
								style={{
									...JoinPageStyles.button,
									backgroundColor: colors.redMain
								}}
								onClick={handlePopupRecognition}
							>
								<span
									style={{
										...JoinPageStyles.buttonText,
										color: colors.whiteMain
									}}
								>
									Okay
								</span>
							</button>
						</div>
					</div>
				) : null}
			</div>
		);
	}

	return (
		<div style={JoinPageStyles.innerPage}>
			<div style={JoinPageStyles.contentContainer}>
				<p style={JoinPageStyles.titleText}>Join a game</p>
				<p
					style={{
						...JoinPageStyles.smallText,
						width: 257,
						fontSize: 18
					}}
				>
					<b>Your name:</b> {randomName}
				</p>
				<div
					style={{
						width: "calc(100% - 40px)",
						maxWidth: "400px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						margin: "0 20px",
						gap: 16
					}}
				>
					<input
						style={{
							...JoinPageStyles.inputBoxStyle,
							outline: "none"
						}}
						type="text"
						value={input}
						onChange={(e) => {
							const text = e.target.value;
							if (text.length > 4) return;
							setInput(text);
							inputRef.current = text;
						}}
						placeholder="Enter room code"
						maxLength={4}
					/>
					<JoinButton disabled={disabled} handleJoinGame={handleJoinGame} />
				</div>

				{inputError !== null ? <p style={JoinPageStyles.errorText}>{inputError}</p> : null}
				<div style={JoinPageStyles.redCircleButton}>
					<a href="/support">
						<FontAwesomeIcon icon={faQuestion} style={{ color: colors.whiteMain }} />
					</a>
				</div>
			</div>
			<Footer />
		</div>
	);
}

function JoinButton({ disabled, handleJoinGame }) {
	const buttonStyles = disabled ? JoinPageStyles.disabledButton : JoinPageStyles.button;
	return (
		<button
			style={{
				...buttonStyles,
				...JoinPageStyles.buttonText,
				backgroundColor: disabled ? colors.redMain : colors.redMain,
				color: colors.whiteMain
			}}
			disabled={disabled}
			onClick={handleJoinGame}
		>
			Join
		</button>
	);
}

function requestFullScreen() {
	const controller = document.getElementById("controller-iframe");

	if (controller.requestFullscreen) {
		controller.requestFullscreen();
	} else if (controller.mozRequestFullScreen) {
		/* Firefox */
		controller.mozRequestFullScreen();
	} else if (controller.webkitRequestFullscreen) {
		/* Chrome, Safari and Opera */
		controller.webkitRequestFullscreen();
	} else if (controller.msRequestFullscreen) {
		/* IE/Edge */
		controller.msRequestFullscreen();
	}
}

function exitFullScreen() {
	const controller = document.getElementById("controller-iframe");

	if (controller.exitFullscreen) {
		controller.exitFullscreen();
	} else if (document.mozCancelFullScreen) {
		/* Firefox */
		document.mozCancelFullScreen();
	} else if (document.webkitExitFullscreen) {
		/* Chrome, Safari and Opera */
		document.webkitExitFullscreen();
	} else if (document.msExitFullscreen) {
		/* IE/Edge */
		document.msExitFullscreen();
	}
}

async function lockLandscapeOrientation() {
	requestFullScreen();
	try {
		// Attempt to lock the screen orientation to landscape
		await window.screen.orientation.lock("landscape");
		console.log("Screen orientation locked to landscape");
	} catch (error) {
		// Handle any errors that occur
		console.error(`Could not lock the screen orientation: ${error}`);
	}
}

async function unlockOrientation() {
	try {
		// Attempt to unlock the screen orientation
		await window.screen.orientation.unlock();
		console.log("Screen orientation unlocked");
	} catch (error) {
		// Handle any errors that occur
		console.error(`Could not unlock the screen orientation: ${error}`);
	}
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinGame);
